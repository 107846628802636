<template>
  <v-container id="cloud-control" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <material-card color="orange" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-domain
                </v-icon>
                場域 - 東面
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5">
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch1"
                cols="12"
                class="px-5 w-100"
                inset
                label="東面捲揚下層"
                :hint="`暫存器：80 關閉紀錄 / 狀態： ( ${switch1.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch2"
                cols="12"
                class="px-5 w-100"
                inset
                label="東面捲揚上層"
                :hint="`暫存器：82 關閉紀錄 / 狀態： ( ${switch2.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex text-center" elevation="3">
              <v-switch
                v-model="switch3"
                cols="12"
                class="px-5 w-100"
                inset
                label="東面捲網"
                :hint="`暫存器：84 關閉紀錄 / 狀態： ( ${switch3.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
          </v-col>
        </material-card>
      </v-col>
      <v-col cols="12" md="6">
        <material-card color="orange" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-domain
                </v-icon>
                場域 - 西面
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5">
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch4"
                cols="12"
                class="px-5 w-100"
                inset
                label="西面捲揚下層"
                :hint="`暫存器：80 關閉紀錄 / 狀態： ( ${switch4.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch5"
                cols="12"
                class="px-5 w-100"
                inset
                label="西面捲揚上層"
                :hint="`暫存器：82 關閉紀錄 / 狀態： ( ${switch5.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex text-center" elevation="3">
              <v-switch
                v-model="switch6"
                cols="12"
                class="px-5 w-100"
                inset
                label="西面捲網"
                :hint="`暫存器：84 關閉紀錄 / 狀態： ( ${switch6.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
          </v-col>
        </material-card>
      </v-col>
      <v-col cols="12" md="6">
        <material-card color="orange" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-domain
                </v-icon>
                場域 - 南面
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5">
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch1"
                cols="12"
                class="px-5 w-100"
                inset
                label="東面捲揚下層"
                :hint="`暫存器：80 關閉紀錄 / 狀態： ( ${switch1.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch2"
                cols="12"
                class="px-5 w-100"
                inset
                label="東面捲揚上層"
                :hint="`暫存器：82 關閉紀錄 / 狀態： ( ${switch2.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex text-center" elevation="3">
              <v-switch
                v-model="switch3"
                cols="12"
                class="px-5 w-100"
                inset
                label="東面捲網"
                :hint="`暫存器：84 關閉紀錄 / 狀態： ( ${switch3.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
          </v-col>
        </material-card>
      </v-col>
      <v-col cols="12" md="6">
        <material-card color="orange" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-domain
                </v-icon>
                場域 - 北面
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5">
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch4"
                cols="12"
                class="px-5 w-100"
                inset
                label="西面捲揚下層"
                :hint="`暫存器：80 關閉紀錄 / 狀態： ( ${switch4.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex mb-5 text-center" elevation="3">
              <v-switch
                v-model="switch5"
                cols="12"
                class="px-5 w-100"
                inset
                label="西面捲揚上層"
                :hint="`暫存器：82 關閉紀錄 / 狀態： ( ${switch5.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
            <app-card class="d-flex text-center" elevation="3">
              <v-switch
                v-model="switch6"
                cols="12"
                class="px-5 w-100"
                inset
                label="西面捲網"
                :hint="`暫存器：84 關閉紀錄 / 狀態： ( ${switch6.toString()} )`"
                :persistent-hint="true"
              />
            </app-card>
          </v-col>
        </material-card>
      </v-col>
    </v-row>
    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
export default {
  name: 'Switch',
  data () {
    return {
      switch1: true,
      switch2: false,
      switch3: false,
      switch4: true,
      switch5: true,
      switch6: false
    }
  }
}
</script>

<style scoped lang="scss">
#myMap {
  display: none;
}
</style>
